import React from 'react'
import logsvg from "../../images/Graphic Side.svg";
import "./login.css"
import pickloadicon from "../../images/pickloadicon.png";
import pickloadtitle from "../../images/pickloadtitle.png";
import icon from "../../images/icon.png";
import { useNavigate } from 'react-router-dom';


const Login = () => {

    const navigate = useNavigate();
    
  const login = () => {
    navigate("/referral");
  };

  return (
    <div className='login-main'>
        <div className='login-main-ctn'>
            <div className='login-ctn'> 
                <img
                    src={icon}
                    width="16%"
                    height="12%"
                    alt='icon'
                />
                <div className='login-props'>
                    <div className='pickload-title-icon-ctn'>
                        <img
                            src={pickloadicon}
                            alt="pickload icon"
                            className='pickload-sign-icon'
                        />
                        <img src={pickloadtitle} width="30%" height="100%" alt="title" className='pickload-title-icon' />
                    </div>
                    <div className='login-text'>
                        <h1>Sign In</h1>
                        <p>Sign in to stay connected.</p>
                        <form>
                            <div className='form-group'>
                                <label>Email</label>
                                <input
                                    id='email'
                                    type='email'
                                    name='email'
                                />
                            </div>
                            <div className='form-group'>
                                <label>Password</label>
                                <input
                                    id='password'
                                    type='password'
                                    name='password'
                                />
                            </div>
                            <button className='login-btn' onClick={login}>Sign in</button>
                        </form>
                    </div>

                </div>
            </div>
            <div className='login-img'>
                <img
                    src={logsvg}
                    width="100%"
                    height="100%"
                    alt='icon'
                />
            </div>
        </div>
    </div>
  )
}

export default Login