import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./download.css";
import pickloadicon from "../../images/pickloadicon.png";
import playstoreicon from "../../images/playstoreicon.png";
import appstoreicon from "../../images/appstoreicon.png";
import phonebody1 from '../../images/phonebody1.png';
import phonebody2 from '../../images/phonebody2.webp';
import phonebody3 from '../../images/phonebody3.png';

const Download = () => {
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  React.useEffect(() => {
    if (inView1) {
      controls1.start({ y: 0, opacity: 1, transition: { duration: 1 } });
    }
  }, [controls1, inView1]);

  React.useEffect(() => {
    if (inView2) {
      controls2.start({ y: 0, opacity: 1, transition: { duration: 2 } });
    }
  }, [controls2, inView2]);

  React.useEffect(() => {
    if (inView3) {
      controls3.start({ y: 0, opacity: 1, transition: { duration: 3 } });
    }
  }, [controls3, inView3]);

  return (
    <div className="download-app-main">
      <div className="download-app-container">
        <div className="download-segment">
          <div className="download-title">
            <img
              src={pickloadicon}
              width="65px"
              height="65px"
              alt="icon"
              className="download-title-icon"
            />
            <h2>Download Pickload App</h2>
          </div>
          <h3>Enjoy affordable deliveries</h3>
          <div className="download-button">            
            <a
              href="https://apps.apple.com/ng/app/pickload/id1662091716"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={appstoreicon}
                alt="icon"
                className="download-btn-icon"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.pickload.pickloaduser&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={playstoreicon}
                alt="icon"
                className="download-btn-icon"
              />
            </a>
          </div>
        </div>
        <div className="grouphone-segment">
          <motion.div
            ref={ref1}
            initial={{ y: -100, opacity: 0 }}
            animate={controls1}
            className="grouphone2"
          >
            <img
              src={phonebody2}
              alt="icon"
              className="phonebody2"
            />
          </motion.div>
          <motion.div
            ref={ref2}
            initial={{ y: -100, opacity: 0 }}
            animate={controls2}
            className="grouphone1"
          >
            <img
              src={phonebody1}
              alt="icon"
              className="phonebody1"
            />
          </motion.div>
          <motion.div
            ref={ref3}
            initial={{ y: -100, opacity: 0 }}
            animate={controls3}
            className="grouphone3"
          >
            <img
              src={phonebody3}
              alt="icon"
              className="phonebody3"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Download;


