import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import pickloadicon from "../../images/pickloadicon.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import youtube from "../../images/youtube.png";
import twitter from "../../images/twitter.png";
import tiktok from "../../images/tiktok.png";

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className="footer-main-body">
      <div className="footer-app-main">
        <div className="footer-col">
          <div className="footer-col1">
            <div className="footer-title">
              <img src={pickloadicon} width="40px" height="40px" alt="icon" />
              <div className="footer-main-title">
                <h3>PICKLOAD</h3>
                <p>MAKING LIFE EASIER</p>
              </div>
            </div>
            <p>
              <span>Pickload</span> was birthed to bridge a gap between at home
              buyers, sellers and the item's logistics. Our Delivery agents
              provide super fast pickup and Delivery services to it's pickload
              users and also provides a way for delivery agents to earn cash as
              well for the services rendered. In short, PICKLOAD serves the
              purpose of connecting these sets of people together in a secure
              and convenient space.
            </p>
          </div>
          <div className="footer-col2">
            <h2 style={{display: "none" }}>Links</h2>
            <div className="col-links">
              <Link to="/">Home</Link>
              <Link to="/faqs">FAQs</Link>
            </div>
          </div>
          <div className="footer-col2">
            <h2 style={{ display: "none" }}>Privacy</h2>
            {/* <h2>Privacy</h2> */}
            <div className="col-links">
              <Link to="/privacy">Privacy</Link>
              <Link to="/Termsandconditions">Terms</Link>
            </div>
          </div>
          <div className="footer-col2">
            <h2>Contact Us</h2>
            <p>+234 916 193 6446</p>
            <p>+234 902 210 6633</p>
            <Link to="/">support@pickload.ng</Link>
            <div className="footer-icons">
              <a
                href="https://www.youtube.com/@pickloadlogistics7714?sub_confirmation=1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube} alt="icon" className="footer-icons-pic" />
              </a>
              <a
                href="https://www.instagram.com/pickload.official/"
                // href="https://www.instagram.com/pickload1/?next=%2F"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="icon" className="footer-icons-pic" />
              </a>
              <a
                href="https://www.tiktok.com/@pickloadofficial?lang=en"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tiktok} alt="icon" className="footer-icons-pic" />
              </a>
              {/* <a
                href="https://www.linkedin.com/in/pickload-logistics-73b215272/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin} alt="icon" className="footer-icons-pic" />
              </a> */}
              <a
                href="https://web.facebook.com/profile.php?id=100077184559128"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="icon" className="footer-icons-pic" />
              </a>
              <a href="https://x.com/pickload" target="_blank" rel="noreferrer">
                <img src={twitter} alt="icon" className="footer-icons-pic" />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <p>© {currentYear} Pickload. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
