import React, { useRef, useEffect, useState } from "react";
import "./home.css";
import { useInView } from "react-intersection-observer";
import Testimonial from "../../component/testimonial/Testimonial";
import Download from "../../component/downloadapp/Download";
import Tutorial from "../../component/videotutorials/Tutorial";
import Question from "../../component/questions/Question";
import Footer from "../../component/footer/Footer";
import Review from "../../component/review/Review";
import Operation from "../../component/operation/Operation";
import deliveryavatar from "../../images/deliveryavatar.webp";
import playicon from "../../images/playicon.png";
import downloadapp from "../../images/downloadapp.png";
import letschat from "../../images/letschat.png";
import letschatportrait from "../../images/letschatportrait.png";
import check from "../../images/check.png";
import downloadicon from "../../images/downloadicon.png";
import deliveryicon from "../../images/deliveryicon.png";
import accounticon from "../../images/accounticon.png";
import landingoverlay from "../../images/landingoverlay.png";
import Motion from "../../component/framer/Motion";
import ReactPlayer from "react-player/lazy";
import mute from "../../images/mute_video.png";
import unmute from "../../images/unmute_video.png";
import play from "../../images/play_video.png";
import pause from "../../images/pause_video.png";
import Loading from "../../component/Loading";
import Guest from "../../component/chat/Guest";
import close from "../../images/cancel.png";

const Home = () => {
  const [ref, inView] = useInView();
  const [sky, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [isLoading, setIsLoading] = useState(true);
  const [main_promo, setMainPromo] = useState(null);
  const [user_promo, setUserPromo] = useState([]);
  const [agent_promo, setAgentPromo] = useState([]);
  const [showModal, setShowModal] = useState(false);


  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleImageClick = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };


  const [isChat, setisChat] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setisChat(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    fetch_ad_videos();
  }, []);

  const fetch_ad_videos = async () => {
    try {
      // set isLoading to true
      // setIsLoading(true);

      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_youtube_videos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { msg, doc } = await response.json();

      if (msg === "Success") {
        let mainPromoItem = {};
        let agentPromoItems = [];
        let userPromoItems = [];

        doc.forEach((item) => {
          // Check the doc_type of each item and add it to the appropriate array
          if (item.doc_type === "main_promo") {
            mainPromoItem = item;
          } else if (item.doc_type === "agent_promo") {
            if (item.video_url !== "no") {
              agentPromoItems.push(item);
            }
          } else if (item.doc_type === "user_promo") {
            if (item.video_url !== "no") {
              userPromoItems.push(item);
            }
          }
        });

        console.log(`----------> ${JSON.stringify(userPromoItems.length)}`);

        setMainPromo(mainPromoItem);
        setUserPromo(userPromoItems);
        setAgentPromo(agentPromoItems);

        setIsLoading(false);

        return;
      }

      throw new Error(msg);
    } catch (error) {
      // setErrorMessage(error.message);
      // setIsLoading(false);
      console.log(error);
    }
  };

  const [playing, setPlaying] = useState(false);
  const [show_main_buttons, setShowMainButtons] = useState(false);
  const playerRef = useRef(null);

  const togglePlay = () => {
    setPlaying(!playing);
    setShowMainButtons(true);
  };

  const handlePlay = () => {
    // console.log("playing....");
    setPlaying(true);
  };

  const handlePause = () => {
    // console.log("stopped playing....");
    setPlaying(false);
  };
  const [isMuted, setIsMuted] = useState(false);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
  };


  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="homepage-container">
          <div className="home">
            <div className="landing-details">
              Door to Door delivery services for <br />
              individuals and business
            </div>
            <div className="landing-summary">
              <p>
                <img src={check} alt="check" className="landing-summary-pic" />
                Secure and Convenient
              </p>
              <p>
                <img src={check} alt="check" className="landing-summary-pic" />
                Always Available For You
              </p>
              <p>
                <img src={check} alt="check" className="landing-summary-pic" />
                Happy Users And Riders
              </p>
            </div>
            <div className="homepage-vid-container">
              <img
                src={landingoverlay}
                alt="icon"
                className="landing-overlay"
              />
              <div className="homepage-overview-container">
                <div className="delivery-avatar">
                  <img
                    src={deliveryavatar}
                    alt="icon"
                    className="delivery-avatar1"
                  />
                </div>
                <div className="homepage-video-parent">
                  <div className="homepage-video">
                    <ReactPlayer
                      ref={playerRef}
                      // url="https://vimeo.com/336812686"
                      // url="https://youtu.be/EuLCkDTWlKg?si=E1AdOGgSNaKVAlFh"
                      url={main_promo?.video_url}
                      light={main_promo?.thumbnail_url}
                      width="100%"
                      height="100%"
                      playing={playing}
                      onPlay={handlePlay}
                      onPause={handlePause}
                      loop
                      muted={isMuted} // Mute the video
                      playIcon={
                        <img
                          src={playicon}
                          alt="icon"
                          className="playicon"
                          onClick={togglePlay}
                        />
                      }
                    />
                  </div>
                  {show_main_buttons ? (
                    <div className="video-player-buttons">
                      {playing ? (
                        <img
                          src={pause}
                          alt="pause"
                          onClick={togglePlay}
                          className="video-buttons"
                        />
                      ) : (
                        <img
                          src={play}
                          alt="play"
                          onClick={togglePlay}
                          className="video-buttons"
                        />
                      )}
                      {isMuted ? (
                        <img
                          src={unmute}
                          alt="unmute"
                          onClick={handleToggleMute}
                          className="video-buttons"
                        />
                      ) : (
                        <img
                          src={mute}
                          alt="mute"
                          onClick={handleToggleMute}
                          className="video-buttons"
                        />
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="downloadapp-home-v">
                  <img
                    src={downloadapp}
                    alt="icon"
                    className="downloadapp-home-v1"
                  />
                  <div>
                    {!isPopupOpen && (
                      <img
                        src={isChat ? letschat : letschatportrait}
                        alt="icon"
                        className="downloadapp-home-v2"
                        onClick={handleImageClick}
                        style={{ cursor: 'pointer' }} // Change cursor to indicate it's clickable
                      />
                    )};

                    {isPopupOpen && <Guest closePopup={closePopup} />}

                  </div>
                </div>
              </div>
            </div>
            <h1 className="hiw">How it works</h1>
            <div className="landing-guide">
              <div className="landing-steps1">
                <img
                  src={downloadicon}
                  alt="icon"
                  className="landing-steps-image"
                />
                <h2>STEP 1</h2>
                <h3>Download the App</h3>
                <p>
                  The Pickload Mobile Application is available on Google
                  Playstore and App store
                </p>
              </div>
              <div className="landing-steps2">
                <img
                  src={accounticon}
                  alt="icon"
                  className="landing-steps-image"
                />
                <h2>STEP 2</h2>
                <h3>Create an Account</h3>
                <p>
                  Follow the prompts provided to easily create an account on
                  Pickload
                </p>
              </div>
              <div className="landing-steps3">
                <img
                  src={deliveryicon}
                  alt="icon"
                  className="landing-steps-image"
                />
                <h2>STEP 3</h2>
                <h3>Make a Delivery Request</h3>
                <p>
                  Request for your item to be delivered to a specified location
                  from the comfort of your home.
                </p>
              </div>
            </div>
            <Operation />
            <h1 className="landings-testimonial">
              Loved by <span>50,000+</span> clients, business <br /> owners &
              marketers worldwides
            </h1>
            <Testimonial videos={user_promo} />
            <div ref={sky}>{inView1 && <Download />}</div>
            <Tutorial />
            <div ref={ref2}>{inView2 && <Review videos={agent_promo} />}</div>
            <Question />
            <div ref={ref}>{inView && <Motion />}</div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
