import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './component/navbar/Navbar';
import Home from './pages/landingpage/Home';
import Policy from './pages/policy/Policy';
import TermsOfService from './pages/service/TermsOfService';
import Guest from './component/chat/Guest';
import Motion from './component/framer/Motion';
import FAQs from './pages/faqs/FAQs';
import Scrolltop from './component/scroll/Scrolltop';
import Review from './component/review/Review';
import Tutorial from './component/videotutorials/Tutorial';
import Download from './component/downloadapp/Download';
import Referal from './pages/referal/Referal';
import PendingReferal from './pages/referal/PendingReferal';
import Login from "./component/signin/Login";
import Footer from './component/footer/Footer';


function App() {
  return (
    <BrowserRouter>
      <Navbar />
        <Scrolltop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Policy />} />
          <Route path="/Termsandconditions" element={<TermsOfService />} />
          <Route path="/chat" element={<Guest />} />
          <Route path="/framer" element={<Motion />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/review" element={<Review />} />
          <Route path="/tutorial" element={<Tutorial />} />
          <Route path="/downloadapp" element={<Download />} />
          <Route path="/referral" element={<Referal />} />
          <Route path="/referral2" element={<PendingReferal />} />
          <Route path="/referral_login" element={<Login />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;