import React from "react";
import Footer from "../../component/footer/Footer";
import "./policy.css";

const Policy = () => {
  return (
    <div className="policy-main">
      <div className="policy-container">
        <h1>Privacy Policy</h1>
        <div className="policy-content">
          <div className="policy-details">
            <h2>Privacy Policy for Users</h2>
            <p>
              PickLoad built the PickLoad app as a Free app. This SERVICE is
              provided by PickLoad at no cost and is intended for use as is.
            </p>
            <p>
              This page is used to inform visitors regarding our policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use our Service.
            </p>
            <p>
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p>
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at PickLoad unless
              otherwise defined in this Privacy Policy.
            </p>
          </div>
          <div className="policy-details">
            <h2>Location Tracking</h2>
            <p>
              To participate as a PickLoad User, you must permit the PickLoad
              App Services to access location services through the permission
              system used by your mobile operating system (“Platform”) or
              browser. We may collect the precise location of your device when
              the PickLoad User App is running in the foreground or background
              of your device. We may also derive your approximate location from
              your IP address. We use your location information to verify that
              you are present in your preferred region or city when you begin or
              engage in a delivery through the PickLoad App, connect you with
              delivery opportunities in your zone, and track the progress and
              completion of your Deliveries. You can enable the location
              tracking feature through the settings on your device or Platform
              or when prompted by PickLoad User App mobile app. If you choose to
              disable the location feature through the settings on your device
              or Platform PickLoad User App will not receive precise location
              information from your device, which will prevent you from being
              able to Trip and receiving delivery opportunities in your area.{" "}
            </p>
          </div>
          <div className="policy-details">
            <h2>Information and Collection Usage</h2>
            <p>
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information. The information that we request will be retained by
              us and used as described in this privacy policy.
            </p>
            <p>
              The app does use third party services that may collect information
              used to identify you.
            </p>
            <p>
              Link to privacy policy of third party service providers used by
              the app
            </p>
            <p>Google Play/Apple Store Services‍</p>
            <span className="policy-sub-heading">Log Data</span>
            <p>
              We want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and
              date of your use of the Service, and other statistics.
            </p>
            <span className="policy-sub-heading">Cookies</span>
            <p>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However, the
              app may use third party code and libraries that use “cookies” to
              collect information and improve their services. You have the
              option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our
              cookies, you may not be able to use some portions of this Service.
            </p>
            <span className="policy-sub-heading">Service Providers</span>
            <p>
              We may employ third-party companies and individuals due to the
              following reasons:
            </p>
            
            <ul className="policy-list-items">
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>
              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
            <span className="policy-sub-heading">Security</span>
            <p>
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </p>
            <span className="policy-sub-heading">Link to Other Sites</span>
            <p>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>
          </div>
          <div className="policy-details">
            <h2>Privacy Policy for Agents </h2>
            <p>
              PickLoad built the PickLoad app as a Free app. The download of
              PickLoad app is at no cost and is intended for use as is.
            </p>
            <p>
              This page is used to inform visitors regarding our policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use our Service.
            </p>
            <p>
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p>
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at PickLoad unless
              otherwise defined in this Privacy Policy.
            </p>
          </div>
          <div className="policy-details">
            <h2>Location Tracking</h2>
            <p>
              To participate as a PickLoad User, you must permit the PickLoad
              App Services to access location services through the permission
              system used by your mobile operating system (“Platform”) or
              browser. We may collect the precise location of your device when
              the PickLoad User App is running in the foreground or background
              of your device. We may also derive your approximate location from
              your IP address. We use your location information to verify that
              you are present in your preferred region or city when you begin or
              engage in a delivery through the PickLoad App, connect you with
              delivery opportunities in your zone, and track the progress and
              completion of your Deliveries. You can enable the location
              tracking feature through the settings on your device or Platform
              or when prompted by PickLoad User App mobile app. If you choose to
              disable the location feature through the settings on your device
              or Platform PickLoad User App will not receive precise location
              information from your device, which will prevent you from being
              able to Trip and receiving delivery opportunities in your area.
            </p>
          </div>
          <div className="policy-details">
            <h2>Information Collection and Use</h2>
            <p>
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information. The information that we request will be retained by
              us and used as described in this privacy policy.
            </p>
            <p>
              The app does use third party services that may collect information
              used to identify you.
            </p>
            <p>
              Link to privacy policy of third party service providers used by
              the app
            </p>
            <p>Google Play/Apple Store Services‍</p>
            <span className="policy-sub-heading">Log Data</span>
            <p>
              We want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and
              date of your use of the Service, and other statistics.
            </p>
            <span className="policy-sub-heading">Cookies</span>
            <p>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However, the
              app may use third party code and libraries that use “cookies” to
              collect information and improve their services. You have the
              option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our
              cookies, you may not be able to use some portions of this Service.
            </p>
            <span className="policy-sub-heading">Service Providers</span>
            <p>
              We may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <ul className="policy-list-items">
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>
              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
            <span className="policy-sub-heading">Security</span>
            <p>
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </p>
            <span className="policy-sub-heading">Links to Other Sites</span>
            <p>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;
