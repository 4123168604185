import React from "react";
import "./referal.css";
import send from "../../images/send.png";
import cart from "../../images/cart.png";
import gift from "../../images/gift.png";
import share from "../../images/share.png";
import copy from "../../images/copy.png";
import Footer from "../../component/footer/Footer";

const PendingReferal = () => {
  const data = [
    { name: "Olu Jacobs", deliveries: 28, reward: "Pending Reward" },
    { name: "Sammie mike", deliveries: 17, reward: "Target not met" },
    { name: "james Bar", deliveries: 17, reward: "Target not met" },
    { name: "Lamer cane", deliveries: 15, reward: "Target not met" },
    { name: "John bush", deliveries: 15, reward: "Target not met" },
    { name: "Oladumi Odinaka", deliveries: 14, reward: "Pending Reward" },
    { name: "Sam Jon", deliveries: 14, reward: "Target not met" },
    { name: "Lola Mike", deliveries: 13, reward: "Target not met" },
    { name: "Roke MOli", deliveries: 12, reward: "Target not met" },
    { name: "Aesi Makinde", deliveries: 11, reward: "Target not met" },
    // Add more data rows as needed
  ];

  return (
    <div>
      <div className="referal-top">
        <h1>Refer a friend!</h1>
        <h2>Win Cash, Airtime & Data Bonuses</h2>
      </div>
      <div className="referal-main-ctn">
        <div className="referal-ctn">
          <p>
            Already <span>213</span> customers shared this offer with friends &
            earned
          </p>
          <h1>HOW IT WORKS</h1>
          <h2>Follow these simple steps</h2>
          <div className="referal-step-main">
            <div className="referal-step">
              <img src={send} width="38px" height="38px" alt="icon" />
              <p>
                {" "}
                <span>Step 1:</span> Invite up to 5 friends to the Pickload App
              </p>
            </div>
            <div className="referal-step">
              <img src={cart} width="38px" height="38px" alt="icon" />
              <p>
                <span>Step 2:</span> Get them to Sign Up using your referral
                code “120212”, and get them to make their firs 5 orders
              </p>
            </div>
            <div className="referal-step">
              <img src={gift} width="38px" height="38px" alt="icon" />
              <p>
                <span>Step 3:</span> Once the referee signs up using the code,
                the referrer stands a chance to win cash, airtime, and data
                bonuses based on completed delivery requests made on the app
              </p>
            </div>
            <div className="referal-code">
              <p>120212</p>
              <div className="refer-share">
                <img
                  src={copy}
                  width="30px"
                  height="30px"
                  alt="icon"
                  onClick={() => {
                    navigator.clipboard.writeText(120212);
                  }}
                />
                {/* <img src={share} width="26px" height="30px" alt="icon" /> */}
              </div>
            </div>
            <a href="/Termsandconditions">Terms & Conditions apply</a>
          </div>
        </div>
        <div className="referal-details">
          <h2>Downliners (Rank - Highest to lowest)</h2>
          <p>
            You’ll be rewarded on every set of 5 downliners who meets the set
            delivery target for reward
          </p>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Completed Deliveries</th>
                <th>Reward type</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.id}>
                  <td>{row.name}</td>
                  <td>{row.deliveries}</td>
                  <td>{row.reward}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PendingReferal;
