import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "./review.css";
import squiggly from "../../images/squiggly.png";
import deliverybike from "../../images/individual_agent.webp";
import deliverybus from "../../images/fleet_agent.webp";
import bikeslide from "../../images/bikeslide.webp";
import rate1 from "../../images/rate1.png";
import frame21 from "../../images/frame21.png";
import frame22 from "../../images/frame22.png";
import groupphone from "../../images/groupphone.webp";
import icon3 from "../../images/icon3.png";
import playstoreicon from "../../images/playstoreicon.png";
import appstoreicon from "../../images/appstoreicon.png";
import reviewportrait from "../../images/reviewportrait.webp";
import reviewcommunity from "../../images/reviewcommunity.webp";
import reviewcommportrait from "../../images/reviewcommportrait.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import linkedin from "../../images/linkedin.png";
import twitter from "../../images/twitter.png";
import youtube from "../../images/youtube.png";
import tiktok from "../../images/tiktok.png";
import playicon from '../../images/playicon.png';
import ReactPlayer from "react-player";

const Review = ({ videos }) => {
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef(null);
  const [current_thumbnail_url, setCurrentThumbnailUrl]  =useState("");
  const [current_video_url, setCurrentVideoUrl] = useState("");
  const [current_username, setCurrentUsername] = useState("");
  const [current_user_occupation, setCurrentOccupation] = useState("");

  const togglePlay = () => {
    setPlaying(!playing);    
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const [isMuted, setIsMuted] = useState(false);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    setCurrentThumbnailUrl(videos[0]?.thumbnail_url);
    setCurrentVideoUrl(videos[0]?.video_url);
    setCurrentUsername(videos[0]?.username ?? "...");
    setCurrentOccupation(videos[0]?.user_occupation ?? "...");
  }, []);


  const rightDivRef1 = useRef(null);

  useEffect(() => {
    const handleScroll1 = () => {
      const rightDiv = rightDivRef1.current;
      if (rightDiv) {
        const scrollPosition = window.scrollY + window.innerHeight;
        const divPosition = rightDiv.offsetTop + rightDiv.offsetHeight / 2;
        if (scrollPosition > divPosition) {
          rightDiv.style.opacity = 1;
          rightDiv.style.transform = "translateX(0)";
        }
      }
    };

    window.addEventListener("scroll", handleScroll1);
    return () => {
      window.removeEventListener("scroll", handleScroll1);
    };
  }, []);

  return (
    <div className="review-main-container">
      <div className="review-container">
        <div className="review-properties">
          <div className="review-details1">
            <motion.div
              ref={rightDivRef1}
              initial={{ opacity: 0, x: "100vw" }} // Initial position, off-screen to the right
              animate={{ opacity: 1, x: 0 }} // Final position, animate to the center of the screen
              transition={{ type: "spring", stiffness: 60, damping: 20 }} // Animation transition
              style={{ flex: 1 }}
            >
              <div className="review-portrait">
                <img
                  src={reviewportrait}
                  alt="icon"
                  className="review-portrait-pic"
                />
              </div>
            </motion.div>
            <img src={squiggly} alt="icon" />
            <h2>
              Become a <span> Delivery Agent</span> on Pickload
            </h2>
            <p>
              At <span>Pickload</span>, we register logistics companies and
              individuals on our platform who are ready to provide fast,
              reliable and secure logistics services to users. <br />
              The best tool to manage your logistics business, record all
              pickups and deliveries.
            </p>
            <p>To become a delivery agent you can choose to sign up as</p>
            <div className="review-select-box">
              <div className="review-select-box1">
                <img src={deliverybike} alt="icon" />
                <p>Individual Agent</p>
              </div>
              <div className="review-select-box1">
                <img src={deliverybus} alt="icon" />
                <p>Fleet Agent</p>
              </div>
            </div>
          </div>
          <motion.div
            ref={rightDivRef1}
            initial={{ opacity: 0, x: "100vw" }} // Initial position, off-screen to the right
            animate={{ opacity: 1, x: 0 }} // Final position, animate to the center of the screen
            transition={{ type: "spring", stiffness: 60, damping: 20 }} // Animation transition
            style={{ flex: 1 }}
          >
            <div className="review-details2">
              <img src={bikeslide} alt="icon" className="review-details2-img" />
            </div>
          </motion.div>
        </div>
        <div className="review-rating">
          <div className="review-rating1">
            <h2>
              Trusted by 500+ Delivery Agents in <span>Nigeria</span>
            </h2>
          </div>
          <div className="review-rating2">
            <div className="review-rating-details">
              <div className="review-rating-details1">
                <h2>4.8</h2>
                <img src={rate1} alt="icon" />
                <p>Appstore</p>
              </div>
              <div className="review-rating-details1">
                <h2>500+</h2>
                <div className="nigeria-flag">
                  <div className="nigeria-green"></div>
                  <div className="nigeria-white"></div>
                  <div className="nigeria-green"></div>
                </div>
                <p>Users in Nigeria</p>
              </div>
            </div>
          </div>
          <div className="review-rating3">
            <div className="review-rating-details">
              <div className="review-rating-details1">
                <h2>4.8</h2>
                <img src={rate1} alt="icon" />
                <p>Playstore</p>
              </div>
              <div className="review-rating-details2">
                <h2>1000+</h2>
                <img src={rate1} alt="icon" />
                <p>Deliveries</p>
              </div>
            </div>
          </div>
        </div>
        <div className="review-trust">
          <div className="review-trust1">
            <div  className="review-trust1-picture">
              <img src={frame22} alt="icon" />
            </div>
            <div className="review-trust2-picture">
              <img src={frame21} alt="icon" />
            </div>
          </div>
          <div className="review-trust-main-video">
            <div className="review-trust-video-frame">
              <ReactPlayer
                ref={playerRef}
                url={current_video_url}
                light={current_thumbnail_url}
                width="100%"
                height="100%"
                playing={playing}
                onPlay={handlePlay}
                onPause={handlePause}
                loop
                muted={isMuted}
                playIcon={
                  <img
                    src={playicon}
                    alt="icon"
                    className="review-playicon"
                    onClick={togglePlay}
                  />
                }
              />
            </div>
            <div className="review-trust-sub-videos-main">
              <>
                {videos.map((review, index) => (
                  <div key={index} className="review-trust-sub-video" >
                    <img
                      src={review.thumbnail_url}
                      key={index}
                      alt="icon"
                      onClick={() => {
                        setCurrentThumbnailUrl(review.thumbnail_url);
                        setCurrentVideoUrl(review.video_url);
                        setCurrentUsername(review.username ?? "...");
                        setCurrentOccupation(review.user_occupation ?? "...");
                        handlePlay();
                      }}
                    />
                  </div>
                ))}
                <div className="review-videos-sub-profile">
                  <span className="review-videos-sub-profile-name">
                    {current_username}
                  </span>
                  <br />
                  <span className="review-videos-sub-profile-occupation">
                    {current_user_occupation}
                  </span>
                </div>
              </>              
            </div>
          </div>
          <div className="review-trust3">
            <div className="review-trust3-picture">
              <img src={reviewcommportrait} alt="icon" />
            </div>
            <div className="rev-portrait">
              <img src={reviewcommunity} alt="icon" className="rev-portrait" />
            </div>
            <div className="review-trust3-props">
              <h2>Over 1000+ users in 2024</h2>
              <div className="review-trust3-icons">
                <a
                  href="https://www.instagram.com/pickload1/?next=%2F"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt="icon"
                    className="review-trust3-icons-pic"
                  />
                </a>
                {/* <a
                  href="https://www.linkedin.com/in/pickload-logistics-73b215272/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedin}
                    alt="icon"
                    className="review-trust3-icons-pic"
                  />
                </a> */}
                <a
                  href="https://web.facebook.com/profile.php?id=100077184559128"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    alt="icon"
                    className="review-trust3-icons-pic"
                  />
                </a>
                <a
                  href="https://twitter.com/pickload52893"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitter}
                    alt="icon"
                    className="review-trust3-icons-pic"
                  />
                </a>
                <a
                href="https://www.youtube.com/@pickloadlogistics7714?sub_confirmation=1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube} alt="icon" className="review-trust3-icons-pic" />
              </a>
              <a
                href="https://www.tiktok.com/@pickloadofficial?lang=en"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tiktok} alt="icon" className="review-trust3-icons-pic" />
                </a>
              </div>
              <p>Join our Community Groups</p>
            </div>
          </div>
        </div>
        <div className="review-support">
          <div className="review-map">
            <img src={groupphone} alt="icon" className="review-map-image" />
          </div>
          <div className="review-map2">
            <div className="review-map-logo">
              <img src={icon3} alt="icon" />
            </div>
            <div className="review-map-content">
              <h2>
                Download <span>Pickload</span>
              </h2>
              <h2>Delivery Agent App</h2>
              <p>
                {" "}
                At <span>Pickload</span>, we register logistics companies and
                individuals on our platform who are ready to provide fast.
                reliable & secure logistics services to users.
              </p>
              <p>
                The best tool to manage your logistics business, record all
                pickups & deliveries
              </p>
              <div className="review-download-button">
                <a
                  href="https://play.google.com/store/apps/details?id=com.pickload.pickloaddeliveryagent"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={appstoreicon}
                    alt="icon"
                    className="review-download-btn"
                  />
                </a>
                <a
                  href="https://apps.apple.com/ng/app/pickload-agent/id1662203435"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={playstoreicon}
                    alt="icon"
                    className="review-download-btn"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
