import React from "react";
import "./referal.css";
import send from "../../images/send.png";
import cart from "../../images/cart.png";
import referal from "../../images/refer.png";
import gift from "../../images/gift.png";
import share from "../../images/share.png";
import copy from "../../images/copy.png";
import Footer from "../../component/footer/Footer";

const Referal = () => {
  return (
    <div className="referal-main">
      <div className="referal-top">
        <h1>Refer a friend!</h1>
        <h2>Win Cash, Airtime & Data Bonuses</h2>
      </div>
      <div className="referal-main-ctn">
        <div className="referal-ctn">
          <p>
            Already <span>213</span> customers shared this offer with friends &
            earned
          </p>
          <h1>HOW IT WORKS</h1>
          <h2>Follow these simple steps</h2>
          <div className="referal-step-main">
            <div className="referal-step">
              <img src={send} width="38px" height="38px" alt="icon" />
              <p>
                {" "}
                <span>Step 1:</span> Invite up to 5 friends to the Pickload App
              </p>
            </div>
            <div className="referal-step">
              <img src={cart} width="38px" height="38px" alt="icon" />
              <p>
                <span>Step 2:</span> Get them to Sign Up using your referral
                code “120212”, and get them to make their firs 5 orders
              </p>
            </div>
            <div className="referal-step">
              <img src={gift} width="38px" height="38px" alt="icon" />
              <p>
                <span>Step 3:</span> Once the referee signs up using the code,
                the referrer stands a chance to win cash, airtime, and data
                bonuses based on completed delivery requests made on the app
              </p>
            </div>
            <div className="referal-code">
              <p>120212</p>
              <div className="refer-share">
                <img src={copy} width="30px" height="30px" alt="icon" onClick={()=>{
                      navigator.clipboard.writeText(120212)
                }
                } />
                {/* <img src={share} width="26px" height="30px" alt="icon" /> */}
              </div>
            </div>
            <a href="/Termsandconditions">Terms & Conditions apply</a> <br />
          </div>
        </div>
        <div className="referal-ctn1">
          <h1>Downliners</h1>
          <a href="/referral2">
          <img src={referal} width="44%" height="auto" alt="icon" />
          </a>
          <h2>No downliner yet?</h2>
          <p>
            Refer a friend & stand a chance to win cash, airtime & data bonuses
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Referal;
