import React from "react";
import "./question.css";
import squiggly2 from "../../images/squiggly2.png";
import squiggly3 from "../../images/squiggly3.png";
import { Link } from "react-router-dom";

const Question = () => {
  return (
    <div className="question-top-container">
      <div className="question-container">
        <img src={squiggly2} alt="icon" className="question-top-left-image" />
        <img
          src={squiggly3}
          alt="icon"
          className="question-bottom-right-image"
        />
        <div className="question-main-container">
          <h1>Frequently Asked Questions</h1>
          <div className="asked-main-container">
            <div className="asked-container1">
              <div className="questions-about">
                <h2>What Services do you offer?</h2>
                <p>We offer a range of logistics services</p>
              </div>
              <div className="questions-about">
                <h2>What are your rates?</h2>
                <p>
                  Our rates vary depending on the service you need and the
                  distance of the delivery. You can check our rates on the app
                  or contact us for a quote.
                </p>
              </div>
              <div className="questions-about">
                <h2>What if I have a problem with my delivery?</h2>
                <p>
                  If you have any issues with your delivery, please contact our
                  customer support team through the app. We will do our best to
                  resolve the issue as quickly as possible.
                </p>
              </div>
              <div className="questions-about">
                <h2>
                  How do you ensure the safety of my goods during
                  transportation?
                </h2>
                <p>
                  We take the safety of your goods very seriously. Our
                  Riders/drivers are trained to handle cargo safely, and we use
                  secure packaging and loading techniques for your peace of
                  mind.
                </p>
              </div>
            </div>
            <div className="asked-container2">
              <div className="questions-about">
                <h2>How do I book your services?</h2>
                <p>
                  You can easily book our services through our app. Simply
                  download the app, create an account, and select the service
                  you need.
                </p>
              </div>
              <div className="questions-about">
                <h2>How do I track my delivery?</h2>
                <p>
                  You can track your delivery in real-time through our app. You
                  will receive updates on the status of your delivery, including
                  when it has been picked up and when it will be delivered.
                </p>
              </div>
              <div className="questions-about">
                <h2>What are the different means of delivery?</h2>
                <p>
                  We have a variety of vehicles available for transportation,
                  including Motor Bikes, Cars and Vans. We can accommodate
                  different sizes and types of cargo. Active means of delivery
                  are available on the app.
                </p>
              </div>
              <div className="questions-about">
                <h2>What areas do you serve?</h2>
                <p>
                  We serve locally within Nigeria, You can check our service
                  areas on the app or contact us for more information on service
                  coverage.
                </p>
              </div>
            </div>
          </div>
          <Link to="/faqs" className="question-btn">
            View More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Question;
